import dayjs from "dayjs";

import { type TerminalSession } from "@/services/openapi";
import { dayMappings } from "@/utils/dates";

const secondsInDay = 86_400;
const secondsInWeek = 604_800;
const delimiter = ", ";
const zeroTime = getTimeFromSeconds(0);
const midnightTime = getTimeFromSeconds(secondsInDay);

function getTimeInSeconds(time: string) {
  const [hours, minutes, seconds] = time.split(":");
  return +hours! * 3600 + +minutes! * 60 + +seconds!;
}

function getTimeFromSeconds(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return `${String(hours).padStart(1, "0")}:${String(minutes).padStart(2, "0")}`;
}

function getDayOfWeekFromSeconds(seconds: number) {
  return Object.values(dayMappings).find(dayNumber => {
    return seconds >= secondsInDay * (dayNumber - 1) && seconds < secondsInDay * dayNumber;
  })!;
}

function getDayOfWeekStartInSeconds(dayOfWeek: number) {
  return (dayOfWeek - 1) * secondsInDay;
}

const getTimeFromWeekSeconds = (seconds: number) => getTimeFromSeconds(seconds % secondsInDay);

// this code seems to be a bit overcomplicated, because of utc time offset.
// there is concept "stamp". It is time in seconds from the beginning of the week:
// 0 seconds is Monday 00:00:00
// 604_800 seconds is Sunday 24:00:00
// For example: we have a utc offset(+4) and session that starts on Sunday 20:00:00 and ends on Sunday 24:00:00:
// Utc offset in seconds = 14_400, Sunday 20:00:00 = 590_400 + 14_400 = 604_800, Sunday 24:00:00 = 604_800 + 14_400 = 619_200
// 619_200 is more 604_800, but is's impossible because Sunday is the last day of the week. So we need to shift it to the Monday.
// So Sunday 24:00:00 with (+4 utc offset) = 619_200 - 604_800 = 14_400 = Monday 04:00:00
// Almost the same logic applies if we have a negative utc offset. The only difference is that we need to shift it to the previous week.
const getSessionInfoValues = ({
  sessions,
  utcOffsetInSeconds,
}: {
  sessions: TerminalSession[];
  utcOffsetInSeconds: number;
}) => {
  const stampInfo: Record<
    number,
    {
      value: number;
      from?: boolean | null;
      to?: boolean | null;
    }
  > = {};

  sessions.forEach(({ dayOfWeek, from, to }) => {
    const dayWeekStart = getDayOfWeekStartInSeconds(dayMappings[dayOfWeek!]);

    const fromTime = dayWeekStart + getTimeInSeconds(from!) + utcOffsetInSeconds;
    const toTime = dayWeekStart + getTimeInSeconds(to!) + utcOffsetInSeconds;

    // if fromTime more than Sunday 24:00:00
    if (fromTime >= secondsInWeek) {
      // monday time
      const stamp = fromTime - secondsInWeek;

      stampInfo[stamp] = { ...stampInfo[stamp], from: true, value: stamp };
    }
    // if fromTime less than Monday 00:00:00
    else if (fromTime < 0) {
      // sunday time
      const stamp = secondsInWeek - Math.abs(fromTime);

      stampInfo[stamp] = { ...stampInfo[stamp], from: true, value: stamp };
    } else {
      stampInfo[fromTime] = { ...stampInfo[fromTime], from: true, value: fromTime };
    }

    // if toTime more than Sunday 24:00:00
    if (toTime >= secondsInWeek) {
      // monday time
      const stamp = toTime - secondsInWeek;

      stampInfo[stamp] = { ...stampInfo[stamp], to: true, value: stamp };
    }
    // if toTime less than Monday 00:00:00
    else if (toTime < 0) {
      // sunday time
      const stamp = secondsInWeek - Math.abs(toTime);

      stampInfo[stamp] = { ...stampInfo[stamp], to: true, value: stamp };
    } else {
      stampInfo[toTime] = { ...stampInfo[toTime], to: true, value: toTime };
    }
  });

  const dayIntervals: Record<
    number,
    {
      from?: boolean | null;
      to?: boolean | null;
      time: string;
    }[]
  > = {};

  Object.values(stampInfo).forEach(({ value, from, to }) => {
    const dayOfWeek = getDayOfWeekFromSeconds(value);

    if (!dayIntervals[dayOfWeek]) {
      dayIntervals[dayOfWeek] = [];
    }

    const dayStart = getDayOfWeekStartInSeconds(dayOfWeek);

    let newTo = to;

    // There may be a case when "to" is equal to the start of a day, so we need to handle it
    // Example: to(0) = Monday 00:00:00
    if (value === dayStart) {
      // There is no need to keep "to" in the case
      // Example: to Sunday 24:00:00 and from Monday 00:00:00
      if (from && to) {
        newTo = null;
      }
      // There is no need to add element to array
      // Example: to Sunday 24:00:00
      else if (to) {
        return;
      }
    }

    dayIntervals[dayOfWeek]!.push({
      from,
      to: newTo,
      // formatted Time
      time: getTimeFromWeekSeconds(value),
    });
  });

  const result: Record<number, { value: string }> = {};

  for (const prop in dayIntervals) {
    const day = +prop;
    const intervalsCount = dayIntervals[day]!.length;
    dayIntervals[day]!.forEach(({ time, from, to }, i) => {
      const isFirst = i === 0;
      const isLast = i === dayIntervals[day]!.length - 1;
      const isMiddle = !isFirst && !isLast;

      if (intervalsCount === 1) {
        // all day available
        if (from && to) {
          result[day] = { value: `${zeroTime}-${midnightTime}` };
          return;
        }
        // to this date available
        if (to) {
          result[day] = { value: `${zeroTime}-${time}` };
          return;
        }
        // from this date available
        result[day] = { value: `${time}-${midnightTime}` };
        return;
      }

      if (isFirst) {
        // Can't be from and to at the same time
        if (to) {
          result[day] = { value: `${zeroTime}-${time}${delimiter}` };
          return;
        }
        result[day] = { value: time };
        return;
      }

      if (isMiddle) {
        // Can't be from and to at the same time
        if (to) {
          result[day] = { value: result[day]!.value + `-${time}${delimiter}` };
          return;
        }
        result[day] = { value: result[day]!.value + time };
        return;
      }

      if (isLast) {
        if (from && to) {
          result[day] = { value: result[day]!.value + `-${midnightTime}` };
          return;
        }
        if (to) {
          result[day] = { value: result[day]!.value + `-${time}` };
          return;
        }
        result[day] = { value: result[day]!.value + `${time}-${midnightTime}` };
        return;
      }
    });
  }

  return result;
};

const getSessionsInfo = ({
  sessions,
  utcOffsetInSeconds,
}: {
  sessions: TerminalSession[];
  utcOffsetInSeconds: number;
}) => {
  const info = getSessionInfoValues({ sessions, utcOffsetInSeconds });

  return Object.values(dayMappings).map(day => {
    return {
      day: dayjs().day(day).format("dddd"),
      value: info[day] ? info[day]!.value : null,
    };
  });
};

export { getSessionsInfo };

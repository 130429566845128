import { cva } from "class-variance-authority";
import parse from "html-react-parser";
import { type FC, type ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Text } from "@/components/text";
import { useAuth } from "@/contexts/auth.context";
import { LanguageSwitcherButton, LanguageSwitcherContainer } from "@/features/language-switcher";
import { useScreenSize } from "@/hooks/screen-size.hook";
import { authRoutes } from "@/routes/auth.routes";
import { ExternalLink } from "@/shared/ui";
import { usePlatformTranslationsQuery } from "@/state/server/platform";

const listItemStyles = cva("mt-[25px] md:mt-0")();

const linkStyles = cva("font-bold text-text-secondary transition-colors hover:text-positive-text")();

const FooterText: FC<{ children: ReactNode }> = ({ children }) => (
  <Text
    color="dimmed"
    family="roboto"
    css={{
      textAlign: "justify",
      fontSize: "12px",
      marginBottom: "10px",
      "& a": {
        color: "$primary",
      },
    }}
  >
    {children}
  </Text>
);

export const Footer: FC = () => {
  const { i18n } = useTranslation();
  const { isMobile } = useScreenSize();
  const { isAuthenticated } = useAuth();
  const { data } = usePlatformTranslationsQuery({ locale: i18n.resolvedLanguage });
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const isAuthPage = location.pathname.includes(authRoutes.signIn) || location.pathname.includes(authRoutes.signUp);

  if (!data) return null;

  return (
    <div className="bg-bg">
      <footer className="mx-auto max-w-[1300px] px-4 py-11 lg:px-[50px]">
        {!isAuthenticated && isMobile && (
          <>
            <LanguageSwitcherButton onOpen={() => setOpen(true)} />
            <LanguageSwitcherContainer open={open} onOpenChange={setOpen} />
          </>
        )}
        {isAuthPage && (
          <div className="mb-8">
            <FooterText>{data["Footer::Text-1"]}</FooterText>
            <FooterText>{data["Footer::Text-2"]}</FooterText>
            <FooterText>{data["Footer::Text-3"]}</FooterText>
            <FooterText>{data["Footer::Text-4"]}</FooterText>
            <FooterText>{data["Footer::Text-5"]}</FooterText>
            <FooterText>{parse(data["Footer::Text-6"]!)}</FooterText>
            <FooterText>{parse(data["Footer::Text-7"]!)}</FooterText>
            <FooterText>
              {data["Footer::ContactUs"]}{" "}
              <ExternalLink href={`mailto:${data["Footer::Email"]}`} target="_blank">
                {data["Footer::Email"]}
              </ExternalLink>{" "}
              {data["Footer::tel"]}:<a href={`tel:${data["Footer::phone-number"]}`}> {data["Footer::phone-number"]}</a>
            </FooterText>
          </div>
        )}

        <div className="mb-8">
          <ul className="md:flex md:gap-20 lg:gap-[125px]">
            <li className={listItemStyles}>
              <a className={linkStyles} target="_blank" rel="noreferrer" href={data["Footer::about-us::doc"]}>
                {data["Footer::about-us::word"]}
              </a>
            </li>
            <li className={listItemStyles}>
              <a className={linkStyles} target="_blank" rel="noreferrer" href={data["Footer::legal-documents::doc"]}>
                {data["Footer::legal-documents::word"]}
              </a>
            </li>
            <li className={listItemStyles}>
              <a className={linkStyles} target="_blank" rel="noreferrer" href={data["Footer::affiliate-program::doc"]}>
                {data["Footer::affiliate-program::word"]}
              </a>
            </li>
            <li className={listItemStyles}>
              <a className={linkStyles} target="_blank" rel="noreferrer" href={data["Footer::help-center::doc"]}>
                {data["Footer::help-center::word"]}
              </a>
            </li>
          </ul>
        </div>
        <div className="h-px bg-bg-border" />
        <div className="mt-8 flex flex-col gap-[25px] lg:flex-row lg:justify-between">
          <Text family="roboto" color={"dimmed"}>
            © {new Date().getFullYear()}, {data["Footer::Copyright"]}
          </Text>
        </div>
      </footer>
    </div>
  );
};
